<template>
  <div class="Gracias">
    <section class="relative top-layer">
      <div class="container">
        <div class="row">
          <div class="col-sm-12">
            <div
              class=""
              style="margin:10vh 0"
            >
              <div class="col-sm-12 bg-dark right">
                <div class="pt64 pb32 p32 pb-xs-64">
                  <h4 class="uppercase">¡Gracias por inscribirte a {{diplomado}}!</h4>
                  <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Vel earum placeat sit cumque tenetur. Nemo, tempore at ab illum modi officia voluptatibus amet vero quaerat minus error rerum, consequatur ea?</p>
                  <router-link
                    to="/"
                    class="btn  btn-filled btn-white"
                    v-text="'Ir a inicio'"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br><br><br><br><br>
    </section>
  </div>
</template>
<script>
export default {
  name: "Inscrito",
  data() {
    return {
      diplomado: "",
    };
  },
  created() {
    if (this.$session.exists() && this.$session.get("diplomado")) {
      this.diplomado = this.$session.get("diplomado");
    } else {
      this.$router.push("/");
    }
    this.$session.destroy();
  },
};
</script>